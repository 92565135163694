<template>
    <b-overlay :show="loading">
        <b-row class="justify-content-center">
            <b-col sm="12" md="11">
                <b-card>
                    <form-filter-export @onExport="print" :type_export="['excel']">
                        <template #default>
                            <b-form-group v-if="!isSales" label="Sales" class="mr-2">
                            <v-select :options="salesOptions" v-model="dataExport.sales_id" label="text" :reduce="option => option.value"></v-select>
                            </b-form-group>
                        </template>
                    </form-filter-export>
                </b-card>
            </b-col>
        </b-row>
    </b-overlay>
</template>
<script>
import FormFilterExport from '@core/components/form-filter-export/FormFilterExport.vue'
import {BFormSelect, BTable, BOverlay, BRow, BCol, BCard, BButton, BFormGroup, BFormInput} from 'bootstrap-vue'
import FormTanggal from './components/FormTanggal'
import vSelect from 'vue-select'
import mixin from './mixin'
export default {
    mixins: [mixin],
    components: {
        FormFilterExport,
        FormTanggal,
        vSelect,
        BFormSelect, BTable, BOverlay, BRow, BCol, BCard, BButton, BFormGroup, BFormInput
    },
    computed: {
        isValidForm() {
            if(!this.form.bulan || !this.form.tahun ) {
                return false
            }

            return true
        }
    },
    data: () => ({
        dataExport: {
            tgl_awal: null,
            tgl_akhir: null,
            sales_id: null,
            konsumen_id: null
        },
        bulanOptions: [
            {
                value: '01',
                text: 'Januari'
            },
            {
                value: '02',
                text: 'Februari'
            },
            {
                value: '03',
                text: 'Maret'
            },
            {
                value: '04',
                text: 'April'
            },
            {
                value: '05',
                text: 'Mei'
            },
            {
                value: '06',
                text: 'Juni'
            },
            {
                value: '07',
                text: 'Juli'
            },
            {
                value: '08',
                text: 'Agustus'
            },
            {
                value: '09',
                text: 'September'
            },
            {
                value: '10',
                text: 'Oktober'
            },
            {
                value: '11',
                text: 'November'
            },
            {
                value: '12',
                text: 'Desember'
            }
        ],
        salesOptions: [],
        selectedSales: null,
        loading: false,
        form: {
            tgl_awal: null,
            tgl_akhir: null
        },
        fields: [
            { key: 'order', label: '#' },
            { key: 'tanggal', label: 'Tanggal' },
            { key: 'nomor', label: 'Nomor' },
            { key: 'akun', label: 'Akun' },
            { key: 'debit', label: 'Debit' },
            { key: 'kredit', label: 'Kredit' },
            { key: 'total', label: 'Total' }
        ]
    }),
    methods: {
        async print({tgl_awal, tgl_akhir, type_export}) {
            if(!tgl_awal || !tgl_akhir) {
                this.displayError({
                message: 'Harap isi tanggal awal dan akhir!'
                })
                return false
            }

            // validate type_export pdf || excel
            const params = {
                tgl_awal,
                tgl_akhir,
                type_export
            }
            if(this.dataExport.sales_id) params.sales_id = this.dataExport.sales_id

            try {
                this.loading = true
                const response = await this.printLaporan(params)
                this.loading = false
                await window.open(response)
                setTimeout(async () => {
                    // delete selected file
                    const arrFile = response.split('/')
                    const filename = arrFile[ arrFile.length - 1 ]

                    await this.clearExportedFile(filename)
                }, 1000)
            }
            catch(e) {
                console.error(e)
                this.loading = false
                this.displayError(e)
                return false
            }
            },
        async getSales() {
            const levels = await this.$store.dispatch('level/getData', {
                search: 'sales'
            })
            const salesLevel = levels.find(level => this.getShortName(level.nama_level) == 'sales')
            const params = {}
            if(salesLevel) params.level_id = salesLevel.id

            const sales = await this.$store.dispatch('pengguna/getData', params)
            this.salesOptions = sales.map(item => ({
                value: item.karyawan.id,
                text: item.karyawan.nama_lengkap
            }))
        },
        async submit({tgl_awal, tgl_akhir}) {
            this.loading = true
            this.form.tgl_awal = tgl_awal
            this.form.tgl_akhir = tgl_akhir
            const response = await this.$store.dispatch('laporan/getModal', this.form)
            window.open(URL.createObjectURL(response))
            this.loading = false
        }
    },
    created() {
        this.getSales()
    }
}
</script>