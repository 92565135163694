import service from '@/services'

export default {
  methods: {
    async clearExportedFile(filename) {
      try {
        const config = {
          url: '/clear-export',
          method: 'get',
          params: {
            filename,
          },
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }
        const response = await service.sendRequest(config)

        return Promise.resolve(response.data)
      } catch (e) {
        if (e.response && e.response.data) {
          const blob = e.response.data
          const text = await blob.text()
          const errorJson = JSON.parse(text)

          return Promise.reject(errorJson)
        }
        return Promise.reject(e)
      }
    },
    async printLaporan(params) {
      try {
        const config = {
          url: '/excel-rekap-dropping',
          method: 'get',
          params,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }
        const response = await service.sendRequest(config)

        return Promise.resolve(response.data)
      } catch (e) {
        return Promise.reject(e)
      }
    },
  },
}
